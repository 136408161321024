.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.title .formElementName {
  margin-left: 6px;
}

.title .formElementType {
  font-size: 14px;
  font-weight: 400;
  color: var(--contrast-5);
}

.title .formElementType::before {
  content: '-';
  margin-left: 10px;
  margin-right: 10px;
}

.commentGroups {
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;
}

.commentGroup + .commentGroup {
  margin-top: 15px;
}

.commentLine {
  display: flex;
}

.commentLine + .commentLine {
  margin-top: 5px;
}

.barStartPoint {
  display: block;
  min-height: 14px;
  flex-shrink: 0;
  width: 6px;
  border-radius: 3px;
  background-color: var(--contrast-3);
}

.value {
  display: block;
  margin-left: 5px;
}

.placeholder {
  color: var(--contrast-5);
}

.modal > * {
  max-width: 750px;
}

.modal .title {
  margin: 35px 0;
}

.modal .subTitle {
  font-weight: 600;
  margin: 12px 0;
}

.modal .sampleTitle {
  display: flex;
  margin: 25px 0 15px;
}

.modal .sample {
  font-weight: 500;
  padding-right: 15px;
}

.modal .sampleName {
  color: var(--contrast-7);
  padding-left: 10px;
}

.modal .answers {
  margin: 0;
  padding: 0;
  list-style: none;
}

.modal .answers > li {
  margin: 10px 0;
  display: flex;
  align-items: stretch;
  column-gap: 10px;
}

.noResultsText {
  color: var(--contrast-6);
}
