.title {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-neutral-8);
  margin-bottom: 20px;
  margin-top: 320px;
}

.description {
  color: var(--color-neutral-6);
  margin-bottom: 40px;
  margin-top: 0px;
}

.error {
  width: 100%;
  margin-bottom: 20px;
}

.form {
  display: flex;
}

.accessCodeInput {
  margin-right: 10px;
  width: 150px;
  text-transform: uppercase;
}

.accessCodeInput::placeholder {
  text-transform: none;
}

.button {
  flex-shrink: 0;
}

.warningIcon {
  margin-bottom: 2px;
}

@media (max-width: 600px) {
  .title {
    margin-top: 240px;
  }
}
