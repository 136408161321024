.noTestsPlaceholder {
  margin-top: 25%;
}

.sensoryTestTagsContainer {
}
.sensoryTestListContainer {
  flex: 1;
}
.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #EBEBEB;
  border-radius: 3px;
}
.selected {
  color: var(--contrast-6);
  font-size: 14px;
  line-height: 17px;
  width: 91px;
}
.remove,
.unselect,
.copy {
  background-color: var(--contrast-3);
}

.unselect span {
  display: none;
}

.deleteModal article{
  max-width: 450px;
}

.testsContainer {
  padding-bottom: 20px;
}
