.modalContent {
  display: flex;
  margin: 40px 0;
}

.illustration {
  width: 200px;
  height: 283px;
  display: block;
}

.paperInfo {
  margin-left: 30px;
}

.paperInfo .title {
  font-weight: 600;
}

.paperInfo .description {
  color: var(--contrast-6);
  margin: 20px 0 30px;
}

.printerIcon {
  margin: 0 8px 0 0;
}
