.cardContainer {
  flex: 1;
}
.card {
  cursor: pointer;
  border-radius: 3px;
  padding: 15px 15px 9px 15px;
  margin-bottom: 20px;
  display: flex;
}

.cardLink {
  flex:1;
  text-decoration: none;
  color: unset;
}

.card:hover, .card.selected {
  background-color: var(--contrast-2);
}

.buttons {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.checkbox {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 3px;
}

.buttons .remove:hover,
.buttons .copy:hover {
  background-color: var(--contrast-3);
}

.buttons .copy {
  margin-top: 5px;
}

.card:hover .buttons {
  visibility: visible;
}

.card:hover .checkbox, .card.selected .checkbox {
  display: flex;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.header .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 9px;
  font-weight: 500;
}

.header .title .logo {
  width: 20px;
  margin-right: 10px;
}

.type {
  margin-top: 0;
  font-size: 14px;
  color: var(--contrast-6);
  margin-left: 30px;
}

.samplesContainer {
  display: flex;
  margin-left: 30px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.samplesContainer .sample {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  margin-right: 20px;
  line-height: 16px;
  color: var(--contrast-8);
  margin-bottom: 6px;
}

.samplesContainer .sample .sampleText {
  margin-left: 10px;
}

.tagsDateCreatedContainer {
  display: flex;
  flex-direction: column;
  align-items: self-end;
  gap: 20px;
}

.tagsContainer {
  display: flex;
  gap:8px;
  flex-direction: row;
}

.dateCreatedContainer {
  font-size: 14px;
  color: var(--contrast-6);
  width: fit-content;
}

.checkboxContainer {
  position: relative;
  cursor: pointer;
  font-size: 22px;
  padding-left: 30px;
}

.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkboxContainer .checkboxMark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: var(--contrast-1);
  border: 2px solid var(--contrast-7);
  border-radius: 5px;
}

.checkboxContainer input:checked ~ .checkboxMark {
  background-color: var(--contrast-7);
}

.checkboxMark:after {
  content:"";
  position: absolute;
  display: none;
}

.checkboxContainer input:checked ~ .checkboxMark:after {
  display: block;
}

.checkboxContainer .checkboxMark:after {
  left: 4px;
  top: -1px;
  width: 3px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
