.navItem {
  text-decoration: none;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: normal;
  width: 100%;
  color: var(--contrast-8);
  padding: 12px 16px;
  border-radius: 3px;
}

.navItem:hover {
  background-color: var(--contrast-3);
}

.navItemActive {
  color: var(--contrast-9);
  font-weight: bold;
  background-color: var(--contrast-1);
}

.navItemActive:hover {
  background-color: var(--contrast-1);
}

.icon {
  height: 16px;
  fill: var(--contrast-8);
}

.navItemActive .icon {
  fill: var(--contrast-9);
}

.navItemText {
  margin-left: 15px;
}
