.boxPlot {
  color: var(--contrast-4);
  font-weight: 500;
  display: flex;
  height: 50px;
}

.minimumValue,
.maximumValue {
  display: inline-block;
  line-height: 14px;
}

.bar {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: 0 10px;
  position: relative;
  height: 14px;
}

.line {
  width: 100%;
  height: 2px;
  background-color: var(--contrast-3);
  padding: 0 10px;
}

.whiskers,
.interquartileRange {
  position: absolute;
  height: 100%;
  border-radius: 3px;
}

.outlier {
  position: absolute;
  width: 8px;
  height: 8px;
  transform: translate(-50%, -50%);
  top: 50%;
  border-radius: 50%;
  background-color: var(--contrast-3);
}

.medianLine {
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: var(--contrast-8);
  transform: translateX(-50%);
}

.medianValue {
  position: absolute;
  display: block;
  color: var(--contrast-8);
  transform: translateX(-50%);
  top: 20px;
}

.noData {
  position: absolute;
  display: block;
  color: var(--contrast-5);
  top: 15px;
  width: 100%;
  text-align: center;
}
