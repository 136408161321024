.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 60px;
}

.title {
  display: flex;
  align-items: center;
}

.icon {
  height: 20px;
  width: auto;
  margin-right: 20px;
}

.title h1 {
  font-size: 24px;
  color: var(--color-neutral-8);
  font-weight: 700;
}

.timer {
  color: var(--color-neutral-6);
}

.form {
  width: 100%;
}

.error {
  margin-bottom: 20px;
  width: 100%;
}

.sampleIdsOrder {
  list-style: none;
  display: flex;
  color: var(--color-neutral-7);
  margin: 0;
  padding: 0;
}

.sampleIdsOrder .id + .id::before {
  content: '';
  width: 4px;
  height: 4px;
  background-color: var(--color-neutral-7);
  display: inline-block;
  margin: 0 5px 3px 5px;
  border-radius: 50%;
}

.info {
  color: var(--color-neutral-6);
}

.hiddenStep {
  display: none;
}

.motivation {
  width: 100%;
  min-height: 70px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}
