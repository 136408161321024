.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  display: flex;
  align-items: center;
}

.color {
  width: 10px;
  height: 10px;
  border-radius: 3px;
  display: block;
  margin-right: 8px;
}
