.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.title .formElementName {
  margin-left: 6px;
}

.title .formElementType {
  font-size: 14px;
  font-weight: 400;
  color: var(--contrast-5);
}

.title .formElementType::before {
  content: '-';
  margin-left: 10px;
  margin-right: 10px;
}

.chartTitle {
  color: var(--contrast-6);
  margin-bottom: 20px;
  display: block;
}

.popularBars {
  list-style: none;
  margin: 0;
  padding: 0;
}

.barChart + .barChart {
  margin-top: 15px;
}

.bar {
  height: 14px;
  border-radius: 3px;
}

.barSize {
  height: inherit;
  border-radius: 3px;
}

.barInfo {
  margin-top: 5px;
}

.percentageNumber {
  font-weight: 600;
  margin-right: 10px;
}

.placeholder {
  color: var(--contrast-5);
}

.modal .title {
  margin: 35px 0;
}

.modal .subTitle {
  font-weight: 600;
  margin: 12px 0;
}

.modal .sampleTitle {
  display: flex;
  margin: 30px 0 15px;
}

.modal .sample {
  font-weight: 500;
  padding-right: 15px;
}

.modal .sampleName {
  color: var(--contrast-7);
  padding-left: 10px;
}

.modal .barChart {
  display: flex;
}

.modal .axisTicks {
  list-style: none;
  margin: 0;
  padding: 0;
}

.modal .axisTicks li {
  margin: 10px 0;
}

.modal .axis {
  display: block;
  flex-shrink: 0;
  width: 4px;
  border-radius: 3px;
  background-color: var(--contrast-3);
  margin: 0 20px;
}

.modal .bars {
  list-style: none;
  margin: 0;
  padding: 0;
  flex-grow: 1;
}

.modal .bars li {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.modal .bar {
  height: 14px;
  display: block;
  background-color: var(--contrast-3);
  border-radius: 3px;
}

.modal .barPercentage {
  display: block;
  margin-left: 10px;
  flex-shrink: 0;
}
