.explanationContainer {
  width: 358px;
  background-color: var(--contrast-2);
  padding: 32px 44px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.aboutContainer {
  margin-top: 297px;
}

.aboutTitle {
  margin-bottom: 36px;
  color: var(--contrast-8);
  font-size: 24px;
  font-weight: 600;
}

.aboutSubTitle {
  margin-bottom: 24px;
  color: var(--contrast-8);
  font-size: 18px;
  font-weight: 600;
}

.aboutDescription {
  margin-bottom: 36px;
  color: var(--contrast-8);
  font-size: 14px;
}

.senslyCredits {
  color: var(--contrast-8);
  font-size: 10px;
  opacity: 0.6;
}

.senslyLink {
  color: inherit;
}

.hidden {
  display: none;
}

@media (max-width: 1000px) {
  .explanationContainer {
    width: 100%;
    padding: 31px;
  }

  .aboutContainer {
    margin-top: 0px;
  }
}
