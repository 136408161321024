.page {
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 52px 25px;
  max-width: 454px;
  width: 100%;
  box-shadow: 0px 2px 100px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-top: 40px;
}

.logo {
  height: 40px;
  max-width: 100%;
  margin-bottom: 50px;
  margin-top: 15px;
}

.inputFields {
  width: 100%;
}

.inputFields input {
  height: 48px;
  line-height: 48px;
  font-size: 16px;
}

.inputFields > * {
  margin-top: 10px;
}

.loginAndForgotPasswordContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.registerContainer {
  color: var(--contrast-7);
  width: 100%;
  margin-top: 30px;
}

.registerContainer a {
  color: var(--contrast-7);
  font-weight: bold;
}

.registerText {
  text-align: center;
  font-size: 14px;
  width: 100%;
}

.loginButton, .loginButton:hover {
  width: 100%;
  justify-content: center;
  margin-top:15px;
  background-color: var(--triangle-3);
  color: var(--contrast-1);
  height: 48px;
}

.spacer {
  width: 100%;
  border: none;
  height: 1px;
  color: var(--contrast-3);
  background-color: var(--contrast-3);
  margin-top: 30px;
  margin-bottom: 30px;
}

.googleButton {
  width: 100%;
  justify-content: center;
  border: 1px solid var(--color-neutral-4);
  background: #fff;
  color: var(--contrast-7);
  height: 48px;
}

.googleIcon {
  margin-right: 15px;
  vertical-align: sub;
}
