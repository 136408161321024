.title {
  color: var(--contrast-6);
  font-size: 14px;
}
.tagsContainer {
  position:fixed;
  height: 89vh;
  max-width: 200px;
}

.tagsInner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 30px;
}

.tagsListContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap:14px;
  max-height: 80vh;
  overflow-y: auto;
}

.newTagContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
  margin-top: 15px;
}
