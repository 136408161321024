.samplesContainer {
  display: flex;
  justify-content: flex-start;
  margin: -10px 0 40px 40px;
  flex-wrap: wrap;
}

.samplesContainer .sample {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  margin-right: 20px;
  line-height: 16px;
  margin-bottom: 12px;
  color: var(--contrast-8);
}

.samplesContainer .sample .sampleText {
  margin-left: 10px;
}

.placeholderContainer {
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noParamsPlaceholderContainer {
  height: 260px;
}

.accessCode {
  background-color: var(--contrast-3);
  border-radius: 3px;
  padding: 2px 6px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 700;
}

.tableSampleIcon {
  display: inline-flex;
}

.progressRatio {
  border: 2px solid var(--color-neutral-3);
  color: var(--qda-3);
  padding: 0 3px 0 6px;
  border-radius: 3px;
  margin-left: -4px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  display: inline-flex;
}

.tableSampleIcon + .tableSampleIcon,
.progressRatio + .tableSampleIcon {
  margin-left: 10px;
}

.removeButton {
  display: inline-flex;
}

.subTitle {
  color: var(--contrast-9);
  font-weight: 500;
  font-size: 18px;
  margin: 60px 0 25px;
}

@media (max-width: 650px) {
  .charts {
    flex-direction: column;
  }

  .lineScaleChart,
  .categoryScaleChart {
    margin: 0;
    width: 100%;
  }

  .categoryScaleChart {
    margin-top: 30px;
  }
}
