.logo {
  height: 46px;
  grid-area: logo;
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  grid-area: scroll;
  padding: 0 10px;
}

.signInButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  grid-area: signIn;
}

.scrollLink {
  display: inline-block;
  cursor: pointer;
  margin-left: 30px;
}

.main {
  margin: 0 auto;
  margin-top: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1150px;
}

.fold {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 120px;
  max-width: 1044px;
  overflow: visible;
  margin-top: 40px;
  gap: 80px;
}

.title {
  text-align: left;
  margin-bottom: 30px;
  font-size: 44px;
}

.title .titleHighlight {
  color: var(--triangle-3)
}

.subTitle {
  font-size: 22px;
  line-height: 22px;
  margin-top: 0px;
  margin-bottom: 40px;
}

.partnersTitle {
  color: var(--contrast-5);
  margin-top: 0;
  margin-bottom: 26px;
}

.companyLogos {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.companyLogo {
  height: 35px;
  filter: grayscale(100%);
}

.companyLogo:not(:last-child) {
  margin-right: 34px;
}

.leftContent {}

.formContainer {
  position: relative;
  padding: 52px;
  padding-top:39px;
  background: #fff;
  box-shadow: 0px 2px 100px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.formTitle {
  text-align: center;
  font-size: 18px;
  color: var(--contrast-9);
  margin-bottom: 10px;
}

.form {
  margin-top: 40px;
}

.form input {
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  margin-top: 15px;
}

.formSubTitle {
  text-align: center;
  font-size: 14px;
  color: var(--contrast-6);
}

.flexRow, .flexRow2 {
  display: flex;
  gap: 15px;
}

.flexRow > div {
  flex: 1 1 0px;
}

.flexRow2 {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--contrast-3);
}
.flexRow2 > div {
  flex: 2;
}

.flexRow2 button, .flexRow2 button:hover {
  background-color: var(--triangle-3);
  color: #fff;
  flex: 1;
  justify-content: center;
  align-self: center;
  height: 48px;
  margin-top:15px;
}

.shape1 {
  position: absolute;
  bottom: -4% !important;
  right: -25% !important;
  width: 117%;
  transform: rotate(85deg);
  z-index: -1;
}

.shape2 {
  position: absolute;
  top: 62%;
  left: -20%;
  z-index: -1;
  width: 137%;
  transform: rotate(10deg);
}

.text {
  color: var(--contrast-7);
  width: 800px;
  max-width: 100%;
  text-align: center;
  margin: 0;
  margin-bottom: 46px;
}

.sectionTitle {
  margin-top: 0;
  margin-bottom: 22px;
  text-align: center;
}

.checkLists {
  margin-bottom: 50px;
}

.checkLists li {
  list-style-type: none;
  font-size: 20px;
  color: var(--contrast-7);
  margin-bottom: 20px;
}

.checkLists li:before {
  content: '';
  display: inline-block;
  height: 24px;
  width: 24px;
  background-image: url('../../assets/check.svg');
  margin-right: 20px;
  margin-bottom: -5px;
}

.googleButton {
  width: 100%;
  justify-content: center;
  margin-top:15px;
  border: 1px solid var(--color-neutral-4);
  background: #fff;
  color: var(--contrast-7);
  height: 48px;
}

.googleIcon {
  margin-right: 15px;
  vertical-align: sub;
}

@media only screen and (max-width: 525px) {
  .title {
    text-align: center;
  }

  .companyLogos {
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }

  .checkLists {
    padding: 0px;
  }
}

@media only screen and (max-width: 900px) {
  .fold {
    justify-content: center;
    flex-direction: column-reverse;
    gap: 0px;
    margin-top: 0px;
  }

  .formContainer, .leftContent {
    margin:30px;
  }
}

@media only screen and (min-width: 1200px) {
  .fold {
    width: calc(100% + 120px);
    margin-left: -60px;
    margin-right: -60px;
  }

  .shape1 {
    bottom: 22%;
    right: 45%;
  }
}
