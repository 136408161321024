.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 25px;
}

.header > :first-child + :nth-child(2) {
  margin-left: 30px;
}

.childrenContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.subTitle {
  display: inline-block;
  margin-top: 6px;
  color: var(--contrast-6);
}

.logo {
  height: 22px;
  margin-right: 14px;
  margin-top: 6px;
}
