.screen {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 600px;
  width: 100%;
  position: relative;
  padding: 0px 28px;
}

.icon {
  position: absolute;
  left: calc(50% - 272px);
  align-self: flex-start;
  top: 30px;
}

.loginFormLoading {
  margin-top: 320px;
}

.smallShape,
.largeShape {
  position: absolute;
}

.largeShape {
  top: -62px;
  right: calc(50% - 272px);
  width: 140px;
  height: 143px;
  z-index: 1;
  transition: background-color 0.6s ease-in-out, transform 0.4s ease-in;
}

.smallShape {
  width: 118px;
  height: 120px;
  top: -82px;
  right: calc(50% - 244px);
  z-index: 2;
  transition: background-color 0.6s ease-in-out, transform 0.4s ease-in;
}

.notFoundPlaceholder {
  margin-top: 80px;
}

.hidden {
  display: none;
}

@media (max-width: 600px) {
  .largeShape {
    top: -62px;
    right: 28px;
    left: unset;
    width: 140px;
    height: 143px;
  }

  .smallShape {
    width: 118px;
    height: 120px;
    left: unset;
    top: -82px;
    right: 56px;
  }

  .icon {
    left: 28px;
  }

  .loginFormLoading {
    margin-top: 240px;
  }
}
