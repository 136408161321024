.modal > article {
  max-width: 450px;
}

.firebaseErrors {}

.colorField {
  margin-top: 35px;
}

.radioGroupColor label {
  display: inline-block;
  padding:9px 9px;
  width: 36px;
  height: 36px;
  box-shadow: none !important;
}

.radioGroupColor label > div > div > div {
  display: none;
}

.radioGroupColor > label > div > div {
  margin-right: 0px !important;
  width: 18px !important;
  height: 18px !important;
}

.tagColorRED > div > div {
  border: solid 2px #813636 !important;
  background: #FFEBEB;
}

.tagColorORANGE > div > div {
  border: solid 2px #66471A !important;
  background: #FFF3E0;
}

.tagColorYELLOW > div > div {
  border: solid 2px #66611A !important;
  background: #FFFCD6;
}

.tagColorOLIVE > div > div {
  border: solid 2px #40661A !important;
  background: #EBFFD6;
}

.tagColorGREEN > div > div {
  border: solid 2px #1C661A !important;
  background: #E1FFE0;
}

.tagColorTEAL > div > div {
  border: solid 2px #196654 !important;
  background: #D6FFF5;
}

.tagColorCYAN > div > div {
  border: solid 2px #1A5966 !important;
  background: #D6F8FF;
}

.tagColorBLUE > div > div {
  border: solid 2px #1A3366 !important;
  background: #EBF2FF;
}

.tagColorPURPLE > div > div {
  border: solid 2px #391966 !important;
  background: #F3EBFF;
}

.tagColorPINK > div > div {
  border: solid 2px #661A66 !important;
  background: #FFEBFF;
}
