body {
  margin: 0;
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}
