.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
}

.icon {
  height: 20px;
  width: auto;
  margin-right: 20px;
}

.title h1 {
  font-size: 24px;
  color: var(--color-neutral-8);
  font-weight: 700;
}

.backArrow[viewBox='0 0 24 24'] {
  width: auto;
  height: 20px;
}

.sampleTitle {
  font-size: 18px;
  color: var(--color-neutral-8);
  font-weight: 600;
  padding: 15px;
  flex-grow: 1;
}

.sampleResultStatusCompleted {
  color: var(--success-2);
  font-size: 14px;
}

.autoSaveNotice {
  font-size: 14px;
  color: var(--color-neutral-6);
  text-align: right;
}

.sampleOptionsContainer {
  margin-top: 60px;
  width: 100%;
}

.completedAlert {
  margin-top: 40px;
  width: 100%;
}

.error {
  margin-bottom: 20px;
  width: 100%;
}

.info {
  color: var(--color-neutral-6);
  white-space: break-spaces;
  margin-top: 40px;
}

.formElementsPlaceholder {
  color: var(--color-neutral-6);
}

.purposeTitle {
  margin-top: 15px;
  font-size: 24px;
  font-weight: 500;
  color: var(--color-neutral-8);
}

.purpose {
  margin-top: 15px;
  font-size: 18px;
  font-weight: 400;
  color: var(--color-neutral-6);
}
