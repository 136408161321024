.icon {
  height: 26px;
  margin-bottom: 66px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-neutral-8);
  margin-bottom: 20px;
}

.description {
  color: var(--color-neutral-6);
  margin-bottom: 36px;
  margin-top: 0px;
}

.error {
  width: 100%;
  margin-bottom: 20px;
}

.inputsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.sampleCodeInput {
  margin-right: 10px;
  width: 68px;
  margin-bottom: 20px;
}

.button {
  flex-shrink: 0;
}

.warningIcon {
  margin-bottom: 2px;
}

.samplesIllustration,
.samplesIllustrationSmall {
  width: 100%;
  margin-top: 140px;
}

.samplesIllustration {
  margin-bottom: 20px;
}

.samplesIllustrationSmall {
  display: none;
  margin-bottom: 34px;
}

@media (max-width: 1000px) {
  .icon {
    margin-bottom: 80px;
  }

  .samplesIllustration {
    display: none;
  }

  .samplesIllustrationSmall {
    display: block;
  }
}
