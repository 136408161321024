.significanceResult {
  display: flex;
}

.isSignificant .icon {
  margin: 5px 10px 0 0;
}

.text {
  margin: 0;
}

.title {
  font-size: 15px;
  font-weight: 500;
  color: var(--contrast-7);
}

.description {
  margin: 0;
  padding: 5px 0 0;
  color: var(--contrast-6);
  font-size: 14px;
}
