.container {
  display: flex;
  justify-content: space-between;
  background: var(--color-neutral-1);
  border-radius: 4px;
  align-items: center;
}

.dragIconContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  visibility: hidden;
  padding-right: 5px;
}

:global(.dragging) .dragIconContainer,
.isDraggable:hover .dragIconContainer {
  visibility: visible;
}

.dragIcon {
  height: 22px;
  cursor: grab;
  align-self: center;
  flex-shrink: 0;
}

.middle {
  flex-grow: 1;
  margin: 0 20px;
}

.title {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: var(--color-neutral-5);
  margin-bottom: 10px;
  font-weight: 500;
}

.name {
  color: var(--color-neutral-8);
  font-size: 15px;
}

.type {
  visibility: hidden;
}

.container:hover .type {
  visibility: visible;
}

.type::before {
  content: '-';
  padding: 10px;
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90px;
  visibility: hidden;
}

.actions > * {
  display: flex;
}

.actions > * + * {
  margin: 8px 0 0;
}

.container:hover .actions {
  visibility: visible;
}

@media (max-width: 400px) {
  .middle {
    align-self: center;
  }

  .title {
    flex-direction: column;
  }

  .type {
    margin: 10px 0 0;
  }

  .type::before {
    display: none;
  }

  .children {
    display: none;
  }
}
