.sampleCard {
  display: flex;
  justify-content: space-between;
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: max-content auto;
  grid-template-areas:
    'sampleName progressCircle'
    'totals totals';
  column-gap: 20px;
  row-gap: 10px;
}

.sample {
  display: flex;
  flex-direction: column;
}

.sampleIcon {
  width: min-content;
}

.sampleName {
  display: inline-block;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 35px;
  width: 140px;
  grid-area: sampleName;
  /* Multiline ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.progressCircle {
  grid-area: progressCircle;
}

.totals {
  grid-area: totals;
  display: flex;
  align-self: end;
}

.totalNumber {
  font-size: 46px;
  color: var(--contrast-8);
  display: block;
  line-height: 34px;
  align-self: start;
  margin-right: 10px;
}

.completedLegend {
  color: var(--contrast-7);
  font-size: 18px;
  font-weight: 600;
  display: block;
}

.inProgressCount {
  display: block;
  margin-top: 5px;
}
