.name {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.nameField {
  flex: 1;
}

.spacer {
  width: 20px;
}

.firebaseErrors {
  margin-bottom: 15px;
}

.dropDownTestIcon {
  display: inline-block;
  width: 14px;
  margin-right: 6px;
}

.sensoryTestIcon {
  height: 14px;
  width: auto;
  margin-right: 6px;
}
