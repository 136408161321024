.formField {
  margin-top: 15px;
  margin-bottom: 15px;
}

.sampleInputContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.sampleFormField {
  flex-grow: 1;
  margin-right: 6px;
  max-width: 400px;
}

.sampleIcon {
  margin-right: 12px;
}

.formGroup {
  display: flex;
  width: 100%;
  gap: 25px;
}

.formGroup > div:first-child {
  flex-grow: 4;
}

.formGroup > div:last-child {
  width: 250px;
}
