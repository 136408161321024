:root {
  --contrast-1: #ffffff;
  --contrast-2: #f4f4f4;
  --contrast-3: #ebebeb;
  --contrast-4: #d6d6d6;
  --contrast-5: #adadad;
  --contrast-6: #7a7a7a;
  --contrast-7: #4d4d4d;
  --contrast-8: #2e2e2e;
  --contrast-9: #000000;

  --triangle-1: #d5f2e1;
  --triangle-2: #8ed7ac;
  --triangle-3: #36b86c;
  --triangle-4: #018e3c;
  --triangle-sample-1-1: var(--triangle-1);
  --triangle-sample-1-2: var(--triangle-4);
  --triangle-sample-2-1: var(--contrast-3);
  --triangle-sample-2-2: var(--contrast-7);

  --twoFive-1: #d6e4ff;
  --twoFive-2: #7fa6f3;
  --twoFive-3: #598ff7;
  --twoFive-4: #002f8e;
  --twoFive-sample-1-1: var(--twoFive-1);
  --twoFive-sample-1-2: var(--twoFive-4);
  --twoFive-sample-2-1: var(--contrast-3);
  --twoFive-sample-2-2: var(--contrast-7);

  --qda-1: #dad5f2;
  --qda-2: #9a8ed7;
  --qda-3: #4b36b8;
  --qda-4: #19018e;
  --qda-sample-1-1: #dad5f2;
  --qda-sample-1-2: #4b36b8;
  --qda-sample-2-1: #ffe2e2;
  --qda-sample-2-2: #eb3737;
  --qda-sample-3-1: #d2e2ff;
  --qda-sample-3-2: #4782f4;
  --qda-sample-4-1: #d5f2e1;
  --qda-sample-4-2: #36b86c;
  --qda-sample-5-1: #fff3d4;
  --qda-sample-5-2: #f6b655;
  --qda-sample-6-1: #f3d2ff;
  --qda-sample-6-2: #c747f4;
  --qda-sample-7-1: #d1eeeb;
  --qda-sample-7-2: #36b8a8;
  --qda-sample-8-1: #f1d3e2;
  --qda-sample-8-2: #b83674;

  --error-1: #ffdede;
  --error-2: #eb3737;

  --success-1: #e1f5e3;
  --success-2: #5cc767;

  --warning-1: #fff3d4;
  --warning-2: #f6b655;

  --min-width: 350px;
}
