.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-size: 16px;
  font-weight: 500;
}

.title .formElementName {
  margin-left: 6px;
}

.title .formElementType {
  font-size: 14px;
  font-weight: 400;
  color: var(--contrast-5);
}

.title .formElementType::before {
  content: '-';
  margin-left: 10px;
  margin-right: 10px;
}

.placeholder {
  color: var(--contrast-5);
}

.noDifferenceText {
  color: var(--contrast-6);
}

.differenceText {
  font-weight: 600;
}

.significanceBullet {
  width: 8px;
  height: 8px;
  background-color: var(--success-2);
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
  margin-top: 6px;
}

.almostSignificanceBullet {
  width: 8px;
  height: 8px;
  background-color: var(--warning-2);
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
  margin-top: 6px;
}

.modal > * {
  max-width: 750px;
}

.modal .title {
  margin: 35px 0;
}

.modal .subTitle {
  font-weight: 600;
  margin: 12px 0;
}

.modal .text {
  color: var(--contrast-6);
}

.modal .distributionRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 25px;
}

.modal .distributionRow .text {
  margin: 0;
}

.modal .legendItem {
  width: 160px;
  margin-left: 6%;
  flex-shrink: 0;
}

.modal .median::before {
  content: '|';
  color: var(--contrast-9);
  font-weight: 600;
  margin-right: 20px;
  margin-left: 5px;
}

.modal .median {
  color: var(--contrast-7);
}

.modal .sampleBoxPlot {
  flex-grow: 1;
}

.modal .sampleTitle {
  display: flex;
  margin-bottom: 15px;
}

.modal .sample {
  font-weight: 500;
  padding-right: 15px;
}

.modal .sampleName {
  color: var(--contrast-7);
  padding-left: 10px;
}

.modal .interquartileRange {
  margin-bottom: 10px;
  display: block;
}

.modal .interquartileRange .bullet,
.modal .quartiles .bullet {
  content: '';
  display: inline-flex;
  width: 12px;
  height: 12px;
  background-color: var(--contrast-3);
  margin-right: 15px;
  border-radius: 3px;
}

.modal .significantDifferenceResult {
  margin: 15px;
  display: flex;
  align-items: center;
}

.modal .significantDifferenceResult > span {
  margin: 10px;
  display: inline-flex;
}

.modal .sampleIcon {
  display: inline-block;
}

.modal .sampleIcon + .sampleIcon {
  margin-left: 10px;
}

.modal .horizontalLegend {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  display: flex;
  column-gap: 30px;
}

.modal .horizontalLegend > li::before {
  content: '';
  display: inline-flex;
  width: 8px;
  height: 8px;
  background-color: var(--contrast-3);
  margin-right: 8px;
  border-radius: 50%;
}

.modal .horizontalLegend > .diff::before {
  background-color: var(--success-2);
}

.modal .horizontalLegend > .almost::before {
  background-color: var(--warning-2);
}
