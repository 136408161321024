.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.frame {
  width: 100%;
  padding: 5px;
  border-radius: 3px;
  height: 38px;
  border: 1px solid var(--contrast-2);
  position: relative;
}

.bar {
  transition: width 0.3s;
  height: 100%;
  border-radius: 3px;
  overflow: hidden;
}

.sub {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.subText {
  font-size: 14px;
  margin-top: 4px;
  color: var(--contrast-7);
}

.noResults {
  font-size: 14px;
  color: var(--contrast-5);
  margin-left: 6px;
}
