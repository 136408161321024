.page + .page {
  margin: 70px 0 0 0;
}

.resultBlocks {
  display: flex;
  margin: 50px 0;
  flex-wrap: wrap;
}

.resultBlocks > * {
  width: 45%;
}

.resultBlocks > *:nth-child(even) {
  margin-left: 10%;
}

.resultBlocks > *:nth-child(n + 3) {
  margin-top: 50px;
}
