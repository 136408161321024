.samplesContainer {
  display: flex;
  justify-content: flex-start;
  margin: -10px 0 40px 40px;
  flex-wrap: wrap;
}

.sampleA,
.sampleB {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  margin-right: 20px;
  line-height: 16px;
  color: var(--contrast-7);
}

.sampleA .sampleText,
.sampleB .sampleText {
  margin-left: 10px;
}

.formLink {
  text-decoration: none;
}

.parameters {
  margin: 50px auto 0 auto;
  padding: 0;
  list-style: none;
  max-width: 700px;
}

.parameter {
  margin: 20px 0 0 0;
  padding: 0;
}

.parameterTitle {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: var(--color-neutral-5);
  margin-bottom: 10px;
  font-weight: 500;
}

.parameterTitle .name {
  color: var(--color-neutral-8);
  font-size: 15px;
}

.parameterTitle .type::before {
  content: '-';
  padding: 10px;
}

.radioGroup {
  display: flex;
  flex-wrap: wrap;
}

.radioGroup > .radioItem {
  padding-left: 0;
  padding-top: 5px;
}

.commentInput {
  height: 60px;
}

.sampleIds {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.accessCode {
  background-color: var(--contrast-3);
  border-radius: 3px;
  padding: 2px 6px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 700;
}

.sampleId {
  margin-left: 8px;
}

.sampleId:first-child {
  margin-left: 0;
}

.sampleDivider {
  display: inline-block;
  height: 5px;
  width: 5px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: var(--contrast-4);
}

.numberOfPassedResults {
  margin: 30px 0 20px;
}

.resultsCalculationBarChart {
  margin-bottom: 30px;
}

.placeholderContainer {
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.removeButton {
  display: inline-flex;
}
