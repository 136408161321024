.tagInput {
  position: relative;
}

.suggestions {
  width: 100%;
  position: absolute;
  top: 65px;
  border-radius: 3px;
  padding: 14px;
  background: var(--contrast-1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.suggestion {
  padding: 1px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  border-radius: 100px;
  cursor: pointer;
}

.highlight {
  font-weight: bold;
}
