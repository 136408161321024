.step {
  display: flex;
}

.step + .step {
  margin-top: 30px;
}

.indented {
  flex-grow: 1;
}

.number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  background-color: var(--color-neutral-2);
  flex-shrink: 0;
  margin-right: 20px;
}

.title {
  font-weight: 500;
  color: var(--color-neutral-8);
  font-size: 16px;
  margin: 8px 0 30px;
}
