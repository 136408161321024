.sampleOption {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}

.viewButton {
  margin-left: 16px;
}

.horizontalSpacer {
  flex: 1;
}

.sampleTitle {
  font-weight: 500;
  color: var(--color-neutral-8);
  font-size: 16px;
}

.sampleResultStatusCompleted {
  color: var(--success-2);
  font-size: 14px;
  margin-right: 10px;
}
