.screen {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 600px;
  width: 100%;
  position: relative;
  padding: 36px 31px;
}

.explanationContainer {
  width: 358px;
  background-color: var(--contrast-2);
  padding: 32px 44px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.formContainer {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.smallShape,
.largeShape {
  position: absolute;
}

.largeShape {
  width: unset;
  height: unset;
  top: -17px;
  left: -77px;
  z-index: 1;
  right: unset;
  transition: background-color 0.6s ease-in-out, transform 0.4s ease-in;
}

.smallShape {
  width: 217px;
  top: -53px;
  left: -87px;
  height: unset;
  right: unset;
  z-index: 2;
  transition: background-color 0.6s ease-in-out, transform 0.4s ease-in;
}

.notFoundPlaceholder {
  margin-top: 80px;
}

.hidden {
  display: none;
}

@media (max-width: 1000px) {
  .screen {
    flex-direction: column-reverse;
  }

  .formContainer {
    margin-bottom: 50px;
  }

  .aboutContainer {
    margin-top: 0px;
  }

  .largeShape {
    top: -62px;
    right: calc(50% - 272px);
    left: unset;
    width: 140px;
    height: 143px;
  }

  .smallShape {
    width: 118px;
    height: 120px;
    left: unset;
    top: -82px;
    right: calc(50% - 244px);
  }
}

@media (max-width: 600px) {
  .largeShape {
    top: -62px;
    right: 28px;
    left: unset;
    width: 140px;
    height: 143px;
  }

  .smallShape {
    width: 118px;
    height: 120px;
    left: unset;
    top: -82px;
    right: 56px;
  }
}
