.icon {
  height: 26px;
  margin-bottom: 30px;
}

.title {
  font-size: 30px;
  font-weight: 700;
  color: var(--color-neutral-8);
}

.subTitle {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
  color: var(--color-neutral-7);
}

.warning {
  margin: 50px 0;
  color: var(--color-neutral-6);
}

.error {
  width: 100%;
  margin-top: 30px;
  margin-bottom: -20px;
}

.form {
  display: flex;
}

.accessCodeInput {
  margin-right: 10px;
  width: 150px;
  text-transform: uppercase;
}

.accessCodeInput::placeholder {
  text-transform: none;
}

.button {
  flex-shrink: 0;
}

.warningIcon {
  margin-bottom: 2px;
}
