/* Desktop */

.pane {
  background-color: var(--contrast-2);
  width: 260px;
  height: 100%;
  padding: 45px 40px 35px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menuTop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menuTop .logo {
  margin-bottom: 40px;
}

.menuTop .logo .logoImg {
  height: 34px;
}

.menuBottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menuBottom .link {
  margin-top: 14px;
  text-decoration: none;
  color: var(--contrast-8);
}

.menuBottom .link:hover {
  text-decoration: underline;
}

/* Mobile */

.topBar {
  display: none;
  height: 50px;
  align-items: center;
  background-color: var(--contrast-2);
  position: relative;
  padding-left: 15px;
}

.menuButton {
  padding: 2px 5px;
  background-color: transparent;
}

.menuButton:hover {
  background-color: transparent;
}

.menuIcon {
  height: 16px;
  opacity: 0.7;
}

.mobileLogo {
  height: inherit;
  opacity: 0.7;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.mobileLogo .logoImg {
  height: inherit;
  padding: 12px 20px;
}

@media (max-width: 900px) {
  .overlay {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    transition-duration: 0.5s;
    transition-property: transform;
  }

  .draggable {
    padding-right: 15px;
    position: fixed;
    z-index: 101;
    top: 0;
    bottom: 0;
  }

  .draggable.dragTransition {
    transition-duration: 0.2s;
  }

  .topBar {
    display: flex;
  }
}
