@property --progressPercentage {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

.background {
  position: relative;
}

.pie {
  --progressPercentage: 20;
  --barWidth: 22px;
  --color: darkred;
  --width: 150px;

  width: var(--width);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
  animation: p 0.5s 0s both;
}

.pie:before,
.pie:after {
  content: '';
  position: absolute;
  border-radius: 50%;
}

.pie:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--color) 98%, #0000) top/var(--barWidth)
      var(--barWidth) no-repeat,
    conic-gradient(var(--color) calc(var(--progressPercentage) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--barWidth)),
    #000 calc(100% - var(--barWidth))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--barWidth)),
    #000 calc(100% - var(--barWidth))
  );
}

.pie:after {
  inset: calc(50% - var(--barWidth) / 2);
  background: var(--color);
  transform: rotate(calc(var(--progressPercentage) * 3.6deg))
    translateY(calc(50% - var(--width) / 2));
}

.noRound:before {
  background-size: 0 0, auto;
}
.noRound:after {
  content: none;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes p {
  from {
    --progressPercentage: 0;
  }
}
