.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.placeholderImage {
  margin-bottom: 20px;
}

.placeholderIconSmall {
  height: 50px;
}

.placeholderIconNormal {
  height: 80px;
}

.placeholderIconLarge {
  height: 100px;
}

.placeholderTitle {
  color: var(--contrast-5);
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 0;
}

.placeholderDescription {
  margin-top: 0;
  color: var(--contrast-5);
  font-weight: 500;
  font-size: 14px;
}
