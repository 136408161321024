.description {
  color: var(--contrast-6);
}

.form {
  margin: 30px 0 0;
}

.multipleInputs {
  display: flex;
}

.editWarning {
  margin-bottom: 15px;
}

.multipleInputs > * + * {
  margin-left: 10px;
}

.nameAndGraphLabelInputsContainer {
  display: flex;
  flex-direction: row;
}

.nameFormField {
  flex: 1;
}

.graphLabelFormField {
  flex: 1;
  max-width: calc((100% - 20px) / 3);
  margin-left: 10px;
}
