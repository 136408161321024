.title {
  font-size: 15px;
  margin-bottom: 30px;
  font-weight: 500;
  text-align: center;
  display: block;
  color: var(--contrast-8);
}

.chart {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px 15px;
}

.parameterName {
  color: var(--contrast-7);
  align-self: center;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 120px;
}

.bars {
  grid-column-start: 2;
}

.bar {
  height: 16px;
  background-color: var(--contrast-2);
  border-radius: 3px;
  display: flex;
  overflow: hidden;
}

.tooltipContainer {
  display: block;
  margin-top: 10px;
}

.tooltipContainer:first-child {
  margin-top: 0;
}

.segment {
  height: 16px;
}

.legend {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.legendItem {
  display: block;
  color: var(--contrast-6);
  font-size: 14px;
  margin: 10px 0 0;
  cursor: default;
  user-select: none;
}

.legendItem + .legendItem {
  margin-left: 20px;
}

.legendItem .circle {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

.legendItem .selected {
  text-decoration: line-through;
}
