article {
  max-width: 690px !important;
}

.nameFormField {
  flex-grow: 1;
}

.formField {
  margin-bottom: 20px;
}

.firebaseErrors {
  margin-bottom: 15px;
}

.dropDownTestIcon {
  display: inline-block;
  width: 14px;
  margin-right: 6px;
}

.sensoryTestIcon {
  height: 14px;
  width: auto;
  margin-right: 6px;
}

.formGroup {
  display: flex;
  width: 100%;
  gap: 25px;
}

.formGroup > div:first-child {
  flex-grow: 4;
}

.formGroup > div:last-child {
  width: 250px;
}

.tagsContainer {
  display: flex;
  gap:8px;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 325px;
}

.tag {
  display: flex;
  padding: 1px 2px 1px 8px;
  align-items: flex-start;
  gap: 2px;
  border-radius: 100px;
  align-items: center;
  width: max-content;
}

.tagAction {
  padding: 2px 1px 1px 3px;
  cursor: pointer;
}


