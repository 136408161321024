.tag {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1px 8px;
  border-radius: 100px;
  height: 22px;
  justify-content: center;
  align-items: flex-start;
  background: #F4F4F4;
  max-width: 100%;
  cursor: pointer;
  border: 1px solid var(--contrast-2);
  gap: 2px;
}

.tag.hasSelectedTests {
  max-width: calc(100% - 26px);
}

.tag.hasSelectedTests.untagged {
  max-width: calc(100% - 0px);
}

.tagText {
  font-size: 14px;
  font-weight: 400;
  height: 17px;
  line-height: 17px;
  color: var(--contrast-7);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tagContainer {
  display: flex;
  max-width: 100%;
}

.checkBoxContainer {
  flex: 0 0 26px;
  align-items: center;
}

.isUndetermined > div{
  background-image: url('./indeterminate.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
}

.selected {
  border: 1px solid var(--contrast-7);
  padding: 1px 2px 1px 8px;
}

.selected.untagged, .tag.hasSelectedTests {
  padding: 1px 8px;
}

.deleteTagButton {
  display: flex;
  width: 17px;
  height: 17px;
  align-items: center;
  justify-content: center;
}

.deleteIcon {
  width: 11px !important;
  height: 11px !important;
}
