.sample {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  margin-top: 10px;
}

.sample:first-child {
  margin-top: 0;
}

.sampleIcon {
  margin-right: 12px;
}

.formField {
  margin-top: 15px;
  margin-bottom: 15px;
}

.formGroup {
  display: flex;
  width: 100%;
  gap: 25px;
}

.formGroup > div:first-child {
  flex-grow: 4;
}

.formGroup > div:last-child {
  width: 250px;
}

.checkGroup {
  display: flex;
  flex-direction: column;
}
