.overview {
  position: relative;
}

.scrollArea {
  overflow-x: hidden;
}

.content {
  height: 350px;
  display: grid;
  grid-template-rows: 170px 170px;
  grid-template-columns: repeat(auto-fill, 253.5px);
  row-gap: 10px;
  column-gap: 10px;
  grid-auto-flow: column;
  width: max-content;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.lineScaleChartCard {
  grid-row: span 2;
  grid-column: span 2;
  width: 100%;
}

.button {
  position: absolute;
  top: 50%;
}

.buttonLeft {
  left: 0;
  transform: translate(calc(-100% - 10px), -50%);
}

.buttonRight {
  right: 0;
  transform: translate(calc(100% + 10px), -50%);
}

@media (max-width: 900px) {
  .buttonLeft {
    transform: translate(-50%, -50%);
  }

  .buttonRight {
    transform: translate(50%, -50%);
  }
}
