.form {
  margin-top: 40px;
  width: 100%;
}

.formNavigation {
  width: 100%;
  margin-bottom: 40px;
}

.formElementInput {
  margin-bottom: 25px;
}

.buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
}

.leftButton {
  margin-right: 10px;
}

.rightButton {
  margin-left: auto;
}
