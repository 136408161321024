.description {
  color: var(--contrast-6);
}

.form {
  margin: 30px 0 0;
}

.optionRow {
  margin-bottom: 10px;
}

.optionRowWrapper {
  display: flex;
}

.optionInput {
  max-width: 400px;
}

.deleteButton {
  margin-left: 10px;
}

.textArea {
  resize: none;
}

.editWarning {
  margin-bottom: 15px;
}

.previewRadioGroup {
  margin-top: 15px;
}

.previewRadioItem {
  padding: 8px 16px 8px 0;
  box-shadow: none !important;
}

.previewRadioItem:hover {
  background: inherit;
}

.nameAndGraphLabelInputsContainer {
  display: flex;
  flex-direction: row;
}

.nameFormField {
  flex: 1;
}

.graphLabelFormField {
  flex: 1;
  max-width: 30%;
  margin-left: 10px;
}
