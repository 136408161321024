.title {
  font-size: 24px;
  font-weight: 500;
  color: var(--color-neutral-8);
}

.subTitle {
  margin-top: 15px;
  font-size: 18px;
  font-weight: 400;
  color: var(--color-neutral-6);
}

.purposeTitle {
  margin-top: 15px;
  font-size: 24px;
  font-weight: 500;
  color: var(--color-neutral-8);
}

.purpose {
  margin-top: 15px;
  font-size: 18px;
  font-weight: 400;
  color: var(--color-neutral-6);
}
