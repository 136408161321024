.tabHeader {
  margin: 6px 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.text {
  flex-shrink: 1;
}

.children {
  margin-left: 50px;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.children > * {
  width: max-content;
}

.children > * + * {
  margin-left: 5px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.description {
  color: var(--contrast-6);
  margin: 15px 0 0 0;
}
