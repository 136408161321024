.sampleIconContainer {
  background-color: var(--qda-1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 1px 5px;
  border-radius: 3px;
  height: min-content;
}

.sampleId {
  color: var(--qda-4);
  font-size: 12px;
  font-weight: 700;
  align-items: center;
}
