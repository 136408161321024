.modal article {
  max-width: 800px;
}

.description {
  color: var(--contrast-6);
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.header .description {
  margin: 10px 0 0;
}

.header .right {
  margin-left: 30px;
  flex-shrink: 0;
}

.formLink {
  text-decoration: none;
}

.alertContainer {
  margin-bottom: 15px;
}

.editWarning {
  margin-bottom: 15px;
}

.placeholder {
  margin: 60px 0 0;
}

.content {
  margin: 30px 0 20px;
}

.list {
  margin: 0 0 0 40px;
  padding: 0;
}

.elementSpacing {
  height: 30px;
}

.insertFormElement {
  display: flex;
  padding-left: 39px;
  align-items: center;
}

.insertFormElement .separator {
  display: flex;
  flex-grow: 1;
  height: 2px;
  background-color: var(--color-neutral-2);
  visibility: hidden;
}

.insertFormElement .actions {
  width: 110px;
  display: flex;
  align-items: center;
  visibility: hidden;
  padding-left: 20px;
}

.insertFormElement:hover .separator,
.insertFormElement:hover .actions {
  visibility: visible;
}

.insertFormElement + .pageItem {
  padding-top: 50px;
}

.insertFormElement:first-child + .pageItem {
  padding-top: 0;
}

.slider {
  padding-left: 0;
  padding-right: 0;
}

.radioGroup {
  margin: 20px 0 5px;
}

.radioGroup > .radioItem {
  margin: 20px 0 0;
  padding: 0;
}

.commentInput {
  height: 60px;
  resize: none;
}

.addFormElementButton {
  margin: 0 auto;
}

@media (max-width: 1050px) {
  .list {
    margin: 0;
  }
}

@media (max-width: 400px) {
  .header {
    flex-direction: column;
  }

  .header .right {
    margin: 20px 0 0;
  }
}
