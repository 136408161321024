.box {
  border: 1px solid var(--contrast-3);
  border-radius: 3px;
  padding: 0 20px 20px;
  margin: 50px 0 0 0;
}

.text {
  background-color: var(--contrast-6);
  color: var(--contrast-1);
  font-weight: 600;
  border-radius: 3px;
  padding: 3px 10px 5px;
  display: block;
  width: max-content;
  margin: -13px 0 20px;
}

.icon {
  margin-right: 6px;
}
