.formNavigation {
  border: 1px solid var(--contrast-3);
  border-radius: 3px;
  padding: 12px 18px;
  display: flex;
}

.navItem,
.pageIndex {
  text-align: center;
  display: inline-flex;
  width: 42px;
  height: 40px;
}

.pageIndex {
  border: 1px solid var(--contrast-3);
  border-radius: 3px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navItem + .pageIndex {
  margin-left: 10px;
}

.text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 14px;
  justify-content: center;
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: var(--contrast-8);
}

.step {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.015em;
  color: var(--contrast-5);
  font-size: 12px;
  margin-top: 5px;
}

.description {
  margin: 20px 0 0;
  padding: 0;
  color: var(--contrast-6);
  font-size: 14px;
  white-space: break-spaces;
}
