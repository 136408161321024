.modal > article {
  max-width: 450px;
}

.tagsContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  flex-wrap: wrap;
}
