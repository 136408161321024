.screen {
  width: 100%;
  height: 100vh;
  min-width: 350px;
  display: flex;
  flex-direction: row;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 0;
  overflow-y: scroll;
}

.content {
  width: 100%;
  height: 100%;
  padding: 35px 60px 60px 60px;
  display: flex;
}

.content.hasSidebar {
  max-width: 100%;
  display: flex;
  gap: 15px;
}

@media (max-width: 900px) {
  .screen {
    flex-direction: column;
  }

  .content {
    padding: 25px 5% 25px;
  }
}
